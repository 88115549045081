import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useForm } from '../../hooks/useForm';
import getQueryVariable, { getAcrValue } from '../utility/getQueryVariable';
import { loadSegment, analyticsTrack } from '../utility/segment';
import EmailInput from '../emailinput/EmailInput';
import PasswordInput from '../PasswordInput';
import ExternalProviders, { Providers } from '../externalproviders/ExternalProviders';
import Loader from '../utility/Loader';
import './Signup.scss';
import Background from '../Background';

export function Signup() {
  const returnUrl = getQueryVariable('returnUrl');
  const clientId = getQueryVariable('clientId');
  const errorFromQuery = getQueryVariable('error');

  const [showLoading, setShowLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorFromQuery || null);
  const [loginLinkUrl, setloginLinkUrl] = useState('');
  const [validEmailAddress, setValidEmailAddress] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [ignoreValidation, setIgnoreValidation] = useState(false);

  const [formState, setFormValue] = useForm({
    emailAddress: '',
    password: ''
  });

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('SignupTitle');
    loadSegment('signup');
    initLoginLinkUrl();
  }, []);

  const getSignupSource = () => {
    return getAcrValue(returnUrl, 'signupsource');
  };

  const initLoginLinkUrl = () => {
    if (returnUrl) {
      setloginLinkUrl(`/login?returnUrl=${encodeURIComponent(returnUrl)}`);
      return;
    }

    Axios('/authenticate/weburl', {
      method: 'GET'
    }).then((response) => {
      setloginLinkUrl(response ? response.data.webUrl : null);
    });
  };

  const isFormValid = () => {
    return formState.emailAddress && validEmailAddress && formState.password && validPassword;
  };

  const onEmailChange = (value: string, isValid: boolean) => {
    setFormValue('emailAddress', value);
    setValidEmailAddress(isValid);
  };

  const onPasswordChange = (value: string, isValid: boolean) => {
    setFormValue('password', value);
    setValidPassword(isValid);
  };

  const onGoFormzProviderSignup = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setShowLoading(true);
    setErrorMessage(null);

    if (!isFormValid()) {
      setShowLoading(false);
      return;
    }

    analyticsTrack('Sign Up Page Clicked', {
      button: 'Create your free account',
      source: getSignupSource()
    });

    try {
      await Axios('/authenticate/signup', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          isPreRegistration: true,
          returnUrl,
          requestData: {
            emailAddress: formState.emailAddress,
            password: formState.password,
            signupEnvironment: getSignupSource()
          }
        }
      });

      const redirect = new URL(window.location.href);
      redirect.pathname = '/signupsurvey';
      redirect.searchParams.append('emailAddress', formState.emailAddress);
      window.location.href = redirect.href;
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowLoading(false);
    }
  };

  const onExternalSignup = (providerId: Providers) => {
    setIgnoreValidation(true);
    setShowLoading(true);
    setErrorMessage(null);

    analyticsTrack('Sign Up Page Clicked', {
      button: providerId.charAt(0).toUpperCase() + providerId.slice(1),
      source: getSignupSource()
    });

    //currently all external providers are oauth
    const generatedForm = document.createElement('form');
    generatedForm.action = `${window.location.origin}/authenticate/oauthsignup`;
    generatedForm.method = 'POST';

    try {
      const data = {
        returnUrl,
        providerId,
        authorizationFailedReturnUrl: window.location.href,
        clientId,
        signupEnvironment: getSignupSource()
      };

      for (const property in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(property)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = property;
          input.value = data[property];
          generatedForm.appendChild(input);
        }
      }

      document.body.appendChild(generatedForm);
      generatedForm.submit();
    } catch (err) {
      setIgnoreValidation(false);
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowLoading(false);
    }
  };

  if (showLoading) {
    return <Loader isLoading />;
  }

  return (
    <div className="signup">
      <Background />
      <div className="content">
        <div className="logo outside-card" onClick={() => window.location.assign('https://www.goformz.com')} />
        <div className="card">
          <div className="header">
            <span>{t('SignupHeader')}</span>
          </div>

          {errorMessage ? <div className="error-state">{errorMessage}</div> : null}

          <form name="signup" onSubmit={onGoFormzProviderSignup}>
            <div className="goformz-provider">
              <EmailInput value={formState.emailAddress} onEmailChange={onEmailChange} ignoreValidation={ignoreValidation} />
              <PasswordInput placeholder={t('PasswordFieldPlaceholder')} onChange={onPasswordChange} showPassword={true} />
            </div>

            <div className="get-started">
              <Button disabled={!isFormValid()} type="submit" onClick={(e) => onGoFormzProviderSignup(e)} className="ui primary button">
                {t('GetStarted')}
              </Button>
            </div>
          </form>
          <div className="terms-of-service">
            <div
              dangerouslySetInnerHTML={{
                __html: t('TermsOfServiceAgreementText', {
                  tosLink: `<a href="https://www.goformz.com/terms" target="_blank" class="terms-text">${t('TermsOfServiceLink')}</a>`
                })
              }}
            />
          </div>
          <ExternalProviders showOrLine={true} text={t('SignupWith')} onProviderClick={onExternalSignup}></ExternalProviders>
          <div className="signup-footer-link mobile">
            {t('ExistingAccountText')}{' '}
            <a href={loginLinkUrl.toString()} className="login-link-button">
              {t('LoginButton')}
            </a>
          </div>
        </div>
        <div className="signup-footer-link">
          <a href={loginLinkUrl.toString()} className="login-link">
            {t('LoginLink')}
          </a>
        </div>
      </div>
    </div>
  );
}
