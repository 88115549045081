import './SignupInvite.scss';
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import getQueryVariable from '../utility/getQueryVariable';
import { loadSegment } from '../utility/segment';
import PasswordInput from '../PasswordInput';
import ExternalProviders, { Providers } from '../externalproviders/ExternalProviders';
import Loader from '../utility/Loader';
import Background from '../Background';
import { canIUseAsync } from '../utility/featureflags/featureFlagUtility';
import { Features } from '../utility/featureflags/featureFlags';

export function SignupInvite() {
  const returnUrl = getQueryVariable('returnUrl');
  const clientId = getQueryVariable('clientId');
  const email = getQueryVariable('email');
  const errorFromQuery = getQueryVariable('error');
  const expires = getQueryVariable('expires');
  const login = !!getQueryVariable('login');
  const token = getQueryVariable('token');

  const [providers, setProviders] = useState<Providers[]>(null);
  const [showLoading, setShowLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(errorFromQuery || null);
  const [password, setPassword] = useState<string>();
  const [validPassword, setValidPassword] = useState(false);
  const [showSetupForInvitedNormalUser, setShowSetupForInvitedNormalUser] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('SignupInviteTitle');
    loadSegment('invite');

    const fetchData = async () => {
      setShowLoading(true);

      const [linkValid, providersLoaded, isEnabled] = await Promise.all([
        validateLink(),
        loadProviders(),
        canIUseAsync(clientId, Features.ShowSetupForInvitedNormalUsers, false)
      ]);

       setShowSetupForInvitedNormalUser(isEnabled);
       setShowLoading(false);
    };
  
    fetchData();
  }, []);

  const validateLink = async () => {
    try {
      await Axios('/authenticate/validations/emailtoken', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          email,
          expires,
          token
        }
      });

      setShowLoading(false);
    } catch (error) {
      const title = t('ContactAdministrator');
      const message = error?.response?.data?.message || t('UnknownError');
      window.location.assign(`/error?title=${title}&message=${message}`);
    }
  };

  const loadProviders = async () => {
    const result = await Axios(`/authproviders/client/${clientId}/activeids`, {
      method: 'GET'
    });

    setProviders(result.data);
  };

  const isGoFormzActive = () => {
    return providers.includes('goformz');
  };

  const isFormValid = () => {
    return validPassword;
  };

  const onPasswordChange = (value: string, isValid: boolean) => {
    setPassword(value);
    setValidPassword(isValid);
  };

  const onGoFormzProviderSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowLoading(true);
    setErrorMessage(null);

    if (!isFormValid()) {
      return;
    }

    try {
      const response = await Axios('/authenticate/resetpassword', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          username: email,
          expires,
          token,
          newPassword: password,
          login,
          returnUrl
        }
      });

      if (showSetupForInvitedNormalUser) {
        const redirect = new URL(window.location.href);
        redirect.pathname = '/usersetupsurvey';
        window.location.href = redirect.href;
      }
      else {
        if (response && response.data && response.data.redirectUrl) {
          window.location = response.data.redirectUrl;
        }
      }

      setShowLoading(false);
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowLoading(false);
    }
  };

  const onExternalSignup = (providerId: string) => {
    setShowLoading(true);
    setErrorMessage(null);

    const generatedForm = document.createElement('form');
    generatedForm.action = `${window.location.origin}/authenticate/oauthlogin`;
    generatedForm.method = 'POST';

    try {
      const data = {
        username: email,
        returnUrl,
        providerId,
        clientId,
        bypassConnectApproval: true,
        authorizationFailedReturnUrl: window.location.href
      };

      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = property;
          input.value = data[property];
          generatedForm.appendChild(input);
        }
      }

      document.body.appendChild(generatedForm);
      generatedForm.submit();
    } catch (err) {
      setErrorMessage(err?.response?.data?.message || t('UnknownError'));
      setShowLoading(false);
    }
  };

  if (!providers || showLoading) {
    return <Loader isLoading />;
  }

  return (
    <div className="signup-invite">
      <Background />
      <div className="content">
        <div className="logo outside-card" onClick={() => window.location.assign('https://www.goformz.com')} />
        <div className="card">
          <div className="header">
            <span>{t('NewUserSetup')}</span>
          </div>

          {errorMessage ? <div className="error-state">{errorMessage}</div> : null}

          <div className="invite-description">
            <span>{t('NewUserInviteText')}</span>
          </div>

          {isGoFormzActive() && (
            <form name="signupinvite" onSubmit={onGoFormzProviderSignup}>
              <div className="goformz-provider">
                <PasswordInput placeholder={t('PasswordFieldPlaceholder')} onChange={onPasswordChange} autofocus={true} useConfirmPassword={true} />
              </div>
              <div className="login">
                <Button disabled={!isFormValid()} type="submit" className="ui primary button">
                  {showSetupForInvitedNormalUser ? t('NextButton') : t('LoginButton')}
                </Button>
              </div>
            </form>
          )}
          <ExternalProviders showOrLine={isGoFormzActive()} onProviderClick={onExternalSignup} activeProviders={providers}></ExternalProviders>
        </div>
      </div>
    </div>
  );
}
